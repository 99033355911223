import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    console.log("Message submited: " + JSON.stringify(data));
  };

  return (
    <>
      <form action="https://formsubmit.co/mgmal.dev@gmail.com" method="POST" /* onSubmit={handleSubmit(onSubmit)} */>
                <input type="hidden" name="_next" value="https://mgamal.com"/>
                <input type="hidden" name="_subject" value="New Web Mail"/>
                <input type="hidden" name="_cc" value="mail@mgamal.com"/>
                <input type="text" name="_honey" style={{display:"none"}}/>
                <input type="hidden" name="_captcha" value="false"/>
                <input type="hidden" name="_template" value="table"/>
        <div className="row">
          <div className="col-md-6">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Full name"
                required
                {...register("name", { required: true })}
              />
              <label className="form-label">Name</label>
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <input
                name="phone"
                type="tel"
                className="form-control"
                placeholder="Phone number"
                required
                pattern="^01[0-2,5]{1}[0-9]{8}$"
                {...register("phone", { required: true })}
              />
              <label className="form-label">Phone</label>
              {errors.phone && errors.phone.type === "required" && (
                <span className="invalid-feedback">Phone is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <input
                name="email"
                type="email"
                className="form-control"
                placeholder="Email address"
                required
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              <label className="form-label">Email</label>
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <input
                name="subject"
                type="text"
                className="form-control"
                placeholder="Subject"
                required
                {...register("subject", { required: true })}
              />
              <label className="form-label">Subject</label>
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div
              className="form-group"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <textarea
                name="message"
                rows="4"
                className="form-control"
                placeholder="Type message"
                required
                {...register("message", { required: true })}
              ></textarea>
              <label className="form-label">Message</label>
              {errors.message && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div
            className="col-12"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div className="btn-bar">
              <button type="submit" className="px-btn px-btn-theme"><strong><b>- SEND YOUR MESSAGE -</b></strong></button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
