import React from "react";

const ServiceContent = [
  {
    icon: "ti-layout-slider",
    no: "01",
    /* service: "", */
    title: "Website Development",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-layout-tab-window",
    no: "02",
    /* service: "", */
    title: "Web Application Development",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-android",
    no: "03",
    /* service: "", */
    title: "Android WebView App Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-wordpress",
    no: "04",
    /* service: "", */
    title: "WordPress Website Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-briefcase",
    no: "05",
    /* service: "", */
    title: "Corporate Website Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-user",
    no: "06",
    /* service: "", */
    title: "Personal Website Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-shopping-cart-full",
    no: "07",
    /* service: "", */
    title: "Online Store Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-book",
    no: "08",
    /* service: "", */
    title: "Courses Website Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-layout-list-thumb-alt",
    no: "09",
    /* service: "", */
    title: "Blogging Website Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-panel",
    no: "10",
    /* service: "", */
    title: "Website Management",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-harddrives",
    no: "11",
    /* service: "", */
    title: "Hosting Services and Domains",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-email",
    no: "12",
    /* service: "", */
    title: "Professional Webmail Services",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-stats-up",
    no: "13",
    /* service: "", */
    title: "Branding Marketing",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-search",
    no: "14",
    /* service: "", */
    title: "Search Engine Marketing (SEO)",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-announcement",
    no: "15",
    /* service: "", */
    title: "Social Media Marketing",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-target",
    no: "16",
    /* service: "", */
    title: "Email Marketing",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-comment-alt",
    no: "17",
    /* service: "", */
    title: "Mobile Marketing (Bulk SMS)",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-bar-chart",
    no: "18",
    /* service: "", */
    title: "Advertising Campaigns",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-flag-alt-2",
    no: "19",
    /* service: "", */
    title: "Branding Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-stamp",
    no: "20",
    /* service: "", */
    title: "Business Identity Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-ink-pen",
    no: "21",
    /* service: "", */
    title: "Logo Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-id-badge",
    no: "22",
    /* service: "", */
    title: "Business Card Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-gallery",
    no: "23",
    /* service: "", */
    title: "Social Media Posters Design",
    url: "#contact",
    animationDelay: "",
  },
  {
    icon: "ti-layout",
    no: "24",
    /* service: "", */
    title: "UI/UX Design",
    url: "#contact",
    animationDelay: "",
  },
];

const ServiceTwoAnimation = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div
            className="col-sm-6 col-lg-4"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
          >
            <div className="feature-box-02">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
              <a href={val.url} className="px-btn px-btn-theme btn-md"><strong><b>Order Now!</b></strong></a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwoAnimation;

{/* import React from "react";

const ServiceContent = [
  {
    icon: "ti-ruler-pencil",
    title: "Development",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "",
  },
  {
    icon: "ti-image",
    title: "Graphic",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "100",
  },
  {
    icon: "ti-layout",
    title: "Web Design",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "200",
  },
  {
    icon: "ti-brush-alt",
    title: "Web Design",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "300",
  },
  {
    icon: "ti-camera",
    title: "Photography",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "400",
  },
  {
    icon: "ti-world",
    title: "Mobile apps",
    descriptions: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    animationDealy: "500",
  },
];

export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-dealay={val.animationDealy}
          >
            <div className="feature-box-01">
              <div>
                <div className="icon">
                  <i className={val.icon}></i>
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </div>
        </div> */}
            {/* End .feature-box-01 */}
          {/* </div>
          ))} */}
        {/* End .col */}
      {/* </div>
    </>
  );
} */}
