import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Education</h4>
          </div>

          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg"><b>2021-Present</b></span>
              <h6 style={{color:"#000"}}>Software Engineering</h6>
              <p className="text-center"><a style={{color:"#1c1d24"}} href="https://www.nisantasi.edu.tr"><b>Nişantaşı University (in Istanbul, Turkey)</b></a></p>
            </li>

            <li>
              <span className="dark-bg"><b>2020-Present</b></span>
              <h6 style={{color:"#000"}}>Computer Science</h6>
              <p className="text-center"><a style={{color:"#1c1d24"}} href="https://www.uopeople.edu"><b>University of the People (Online)</b></a></p>
            </li>

            <li>
              <span className="dark-bg"><b>2020-Present</b></span>
              <h6 style={{color:"#000"}}>Business Administration</h6>
              <p className="text-center"><a style={{color:"#1c1d24"}} href="https://www.uopeople.edu"><b>University of the People (Online)</b></a></p>
            </li>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Experience</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span style={{color:"#000"}} className="theme-bg"><b>6 Years</b></span>
              <h6>Web Development</h6>
              <p className="text-center">Full Stack: (Front-End/Back-End) and More</p>
            </li>

            <li>
              <span style={{color:"#000"}} className="theme-bg"><b>4 Years</b></span>
              <h6>Digital Marketing</h6>
              <p className="text-center">Ads Campaigns in All Platforms and More</p>
            </li>

            <li>
              <span style={{color:"#000"}} className="theme-bg"><b>4 Years</b></span>
              <h6>Graphic Design</h6>
              <p className="text-center">Branding, UI/UX Design and More</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">My Skills</h4>
          </div>
          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <h6>HTML, CSS, JS, React and WordPress</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 102 + "%" }}
                >
                  <span>99%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>PHP, Laravel and MySQL</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 78 + "%" }}
                >
                  <span>75%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Microsoft Office: (Word - Excel)</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 95 + "%" }}
                >
                  <span>92%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Adobe Illustrator/Photoshop</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 91 + "%" }}
                >
                  <span>88%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Adobe XD & UI/UX Design</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 89 + "%" }}
                >
                  <span>86%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
          </div>
        </div>
        {/* End .col */}
      </div>
      <div className="text-center">
      <a className="px-btn px-btn-theme btn-md" href="#services">
        My Services
      </a></div>
    </>
  );
};

export default Skills;
