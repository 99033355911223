import React from "react";
/* import Preview from "../views/Preview";
import HomeLight from "../views/all-home-version/HomeLight";
import HomeLightAnimation from "../views/all-home-version/HomeLightAnimation"; */
import HomeDarkAnimation from "../views/all-home-version/HomeDarkAnimation";
/* import Projects from "../views/all-home-version/Projects"; */
/* import HomeDark from "../views/all-home-version/HomeDark";
import HomeVideoLight from "../views/all-home-version/HomeVideoLight";
import HomeVideoDark from "../views/all-home-version/HomeVideoDark"; */
import NotFound from "../views/NotFound";
import ahlalkanoon from "../views/ahlalkanoon";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          {/* <Route path="/preview" component={Preview} />
          <Route path="/home-light" component={HomeLight} />
  <Route path="/home-light-animation" component={HomeLightAnimation} /> */}
          <Route exact path="/" component={HomeDarkAnimation} />
          <Route exact path="/ahlalkanoon" component={ahlalkanoon} />
          {/* <Route exact path="/Projects" component={Projects} /> */}
          {/*<Route path="/home-dark" component={HomeDark} />
          <Route path="/home-video-light" component={HomeVideoLight} />
<Route path="/home-video-dark" component={HomeVideoDark} /> */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
