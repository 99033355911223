import React from "react";
import TextLoop from "react-text-loop";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="img-box dark-img-box">
            <img src="img/about/about-2.jpg" alt="MOHAMED GAMAL" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-dealay="100"
        >
          <div className="typo-box about-me">
            <h3>Mohamed Gamal</h3>
            <h5>
              I'm a{" "}
              <TextLoop>
                <span className="color-theme loop-text">Web Developer<span style={{color:"#fff"}}>.</span></span>
                <span className="color-theme loop-text">Digital Marketer<span style={{color:"#fff"}}>.</span></span>
                <span className="color-theme loop-text">Graphic Designer<span style={{color:"#fff"}}>.</span></span>
                <span className="color-theme loop-text">UI/UX Designer<span style={{color:"#fff"}}>.</span></span>
                <span className="color-theme loop-text">Freelancer<span style={{color:"#fff"}}>.</span></span>
              </TextLoop>
            </h5>
            {/* <p>
              I design and develop services for customers of all sizes,
              specializing in creating stylish, modern websites, web services
              and online stores. My passion is to design digital user
              experiences through the bold interface and meaningful
              interactions. Check out my Portfolio
            </p> */}
            <div className="row about-list">
            <div className="col-md-6">
                <div className="media">
                  <label>Email</label>
                  <p><a href="mailto:mail@mgamal.com">mail@mgamal.com</a></p>
                </div>
                <div className="media">
                  <label>WhatsApp</label>
                  <p><a href="https://wa.me/message/NDKC32ZFQ47FJ1">+90 536 024 48 76</a></p>
                </div>
                {/* <div className="media">
                  <label>Skype</label>
                  <p>skype.0404</p>
          </div> */}
              </div>
              {/* End .col */}

              <div className="col-md-6">
                {/* <div className="media">
                  <label>Age</label>
                  <p>20 Years</p>
        </div> */}
                <div className="media">
                  <label>Birthday</label>
                  <p>01-01-2001</p>
                </div>
                <div className="media">
                  <label>Location</label>
                  <p>Istanbul, Turkey</p>
                </div>
                {/* <div className="media">
                  <label>Residence</label>
                  <p>Canada</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div> */}
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="https://mgamal.com/MOHAMED GAMAL - (CV)       .pdf" target="_blank">
                <strong><b>- Download CV -</b></strong>
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
